<template lang="pug">
.about
  CommonHeader(:data="headerData")
  .about
    .about-inner.main
  .reason
    .reason-inner
  .service
    .service-inner
  
</template>
<script>
import CommonHeader from '@/components/common-header.vue'
export default {
  components: {
    CommonHeader,
  },
  data() {
    return {
      headerData: {
        title: "专注数据化转型的软件服务",
        content: "小枝科技始终专注于提供全方位的IT技术服务及行业数字化转型解决方案,包括：软件开发、移动端应用开发、人工智能、物联网应用、IT咨询服务产品质量测试运维等。",
        backgroundImage: require('@/assets/images/industry_bg.png')
      }
    }
  },
}
</script>
<style lang="stylus" scoped>
.banner
  height 364px
  background url("../assets/images/industry_bg.png") center no-repeat
  background-size cover
  color #fff
  h2
    margin 0
    font-size 40px
    line-height 56px
    padding-top 105px
    margin-bottom 20px
  .desc
    width 480px
    font-size 16px
.about
  &-inner
    height 402px
    background url("../assets/images/about_bg.png") center no-repeat
    background-size cover
.reason
  &-inner
    height 402px
    background url("../assets/images/about_reason.png") center no-repeat
    background-size 1280px 100%
.service
  &-inner
    height 402px
    background url("../assets/images/about_service_flow.png") center no-repeat
    background-size 1280px 100%
</style>